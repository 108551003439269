import classNames from 'classnames'
import Marquee from 'react-fast-marquee'
import { useScreenSize } from 'hooks'
import { Title } from 'components/text'
import { SubscribeSection } from 'components/modal'
import { Link, Skeleton } from 'components/ui'
import { FRONT_PAGE_KEY, JOURNAL_PAGES, TAG_FEATURED } from './constants'
import { getJournalPageUrl } from './utils'
import { useJournalPosts } from './hooks'
import { ArticlePost } from './articlePost'
import { OtherPosts } from './otherPosts'
import { CategorizedPosts } from './categorizedPosts'

import styles from './journalPage.module.scss'

const FEATURED_POSTS_COUNT = 4

export const JournalPage = ({ page = FRONT_PAGE_KEY }) => {
    const title = JOURNAL_PAGES.find(p => p.key === page)?.name ?? 'Journal'

    const isFrontPage = page === FRONT_PAGE_KEY

    const category = isFrontPage ? '' : title

    const { desktop } = useScreenSize()

    const {
        data: latestPosts,
        isLoading: isLoadingLatest,
        error: errorLatest,
    } = useJournalPosts(category, 0, FEATURED_POSTS_COUNT + 1, isFrontPage ? TAG_FEATURED : '', true)

    const latestPost = latestPosts?.[0]?.attributes

    return (
        <div className={styles.journalPage}>
            <Marquee autoFill>
                <Title className={styles.title}>{title}</Title>
            </Marquee>
            <div className={styles.container}>
                <div className={'container'}>
                    <ul className={styles.pages}>
                        {JOURNAL_PAGES.map((option, idx) => (
                            <li
                                key={`journal-${option.key}-${idx}`}
                                className={classNames(styles.option, {
                                    [styles.selected]: option.key === page,
                                })}
                            >
                                <Link href={getJournalPageUrl(option.key)} label={option.name} blank={false}>
                                    {option.key === FRONT_PAGE_KEY ? 'Front Page' : option.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div
                        className={classNames(styles.posts, {
                            [styles.row]: desktop,
                        })}
                    >
                        <div className={styles.latest}>
                            {isLoadingLatest ? (
                                <Skeleton width={'100%'} height={desktop ? '544px' : '50vw'} />
                            ) : (
                                Boolean(latestPost) && (
                                    <ArticlePost
                                        post={latestPost}
                                        category={category}
                                        isLatest
                                        isDynamic
                                        isLoading={isLoadingLatest}
                                    />
                                )
                            )}
                        </div>
                        <div className={styles.latestPosts}>
                            {isLoadingLatest
                                ? Array.from({ length: FEATURED_POSTS_COUNT }, (_, index) => index).map(idx => (
                                      <div key={`latest-post-${idx}`} className={styles.skeleton}>
                                          <Skeleton width={'100%'} height={'100%'} />
                                      </div>
                                  ))
                                : latestPosts
                                      ?.slice(1, FEATURED_POSTS_COUNT + 1)
                                      ?.map((post, idx) => (
                                          <ArticlePost
                                              key={`latest-post-${post?.slug}-${idx}`}
                                              post={post?.attributes}
                                              category={category}
                                              isDynamic={!desktop}
                                              isLoading={isLoadingLatest}
                                          />
                                      ))}
                        </div>
                    </div>
                    <div className={styles.subscribe}>
                        <SubscribeSection />
                    </div>
                    {isFrontPage ? <CategorizedPosts /> : <OtherPosts category={category} />}
                </div>
            </div>
        </div>
    )
}
