import classNames from 'classnames'
import { CopyLinkButton, HorizontalSeparator, Link, ShareButton, Tag } from 'components/ui'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { baseUrl, readTime, trimUnescapedHtml } from 'utils/actions'
import { JOURNAL_PAGES } from './constants'
import { useUpdatedHtmlWithH2Ids } from './hooks'

import { useEffect, useMemo } from 'react'
import styles from './journalPostPage.module.scss'

const TableOfContentsWithH2 = dynamic(() => import('components/ui').then(module => module.TableOfContentsWithH2), {
    ssr: false,
})

const ArticleContent = dynamic(() => import('./articleContent').then(module => module.ArticleContent), {
    ssr: false,
})

export const JournalPostPage = ({ page = '', post = null }) => {
    const categoryName = JOURNAL_PAGES.find(p => p.key === page)?.name ?? ''
    const hasTldr = Boolean(post?.tldr)

    const link = `${baseUrl}/${page}/${post?.slug}`

    const tags = post?.tags?.data?.map(t => t?.attributes?.name)?.filter(Boolean) ?? []
    const hasTags = !!tags.length

    const contentWithIds = useUpdatedHtmlWithH2Ids(post?.content)

    const authorsString = post?.authors?.data
        ?.map(a => a?.attributes?.nickName)
        .filter(Boolean)
        .join(', ')

    const isInfographic = categoryName === 'Infographics'

    const authorName = post.authors.data.map(author => author.attributes.username)

    const articleSchema = useMemo(
        () => ({
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: post?.title,
            image: [post?.featuredImage?.data?.attributes?.url],
            datePublished: post?.date,
            dateModified: post?.modifiedDate,
            author: [
                {
                    '@type': 'Person',
                    name: authorName,
                },
            ],
        }),
        [post?.title, post?.featuredImage?.data?.attributes?.url, post?.date, post?.modifiedDate, authorName]
    )

    useEffect(() => {
        const script = document.createElement('script')
        script.type = 'application/ld+json'
        script.text = JSON.stringify(articleSchema)
        document.head.appendChild(script)
        return () => {
            document.head.removeChild(script)
        }
    }, [articleSchema])

    return (
        <div className={styles.journalPostPage}>
            <div className={styles.container}>
                <div className='container'>
                    <div className={styles.articlePost}>
                        <div className={styles.postContent}>
                            <div className={styles.image}>
                                <Image
                                    src={
                                        isInfographic
                                            ? post?.infographic?.data?.attributes?.url
                                            : post?.featuredImage?.data?.attributes?.url
                                    }
                                    alt='Post'
                                    width={0}
                                    height={0}
                                    sizes='100vw'
                                    priority
                                />
                            </div>
                            <div className={classNames(styles.text, 'lg:px-20')}>
                                <div className={styles.wrap}>
                                    <div className={styles.topRow}>
                                        <p className={styles.date}>
                                            {new Date(String(post?.date).split('T')[0]).toLocaleDateString('en-US', {
                                                month: 'short',
                                                day: '2-digit',
                                                year: 'numeric',
                                            })}
                                        </p>
                                        <div className={styles.buttons}>
                                            <ShareButton href={link} title={post?.title} hashtags={tags} />
                                            <CopyLinkButton href={link} />
                                        </div>
                                    </div>
                                    <h1 className={styles.title}>{trimUnescapedHtml(post?.title || 'Article')}</h1>
                                </div>
                                <div className={styles.infoWrap}>
                                    <p className={styles.info}>
                                        <Link
                                            label={categoryName}
                                            className={styles.category}
                                            href={`/journal/${page}`}
                                            blank={false}
                                        >
                                            {categoryName}
                                        </Link>
                                        {' · '}
                                        <span>{readTime(post?.content ?? '')}</span>
                                        <span>{authorsString ? ` · Written by ${authorsString}` : ''}</span>
                                    </p>
                                    <TableOfContentsWithH2
                                        htmlContent={contentWithIds}
                                        defaultOptionName='Table of Contents'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <HorizontalSeparator className={styles.separator} />
                    <div className='flex flex-col w-full lg:px-20'>
                        {hasTags && (
                            <div className={styles.tags}>
                                {tags?.map((tag, idx) => (
                                    <Tag key={`tag-${tag}-${idx}`} className={styles.tag} label={tag} minimal={false} />
                                ))}
                            </div>
                        )}
                        {hasTldr && (
                            <div className={classNames('cms', styles.tldr)}>
                                <h2>TL;DR:</h2>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: post?.tldr,
                                    }}
                                />
                            </div>
                        )}
                        <ArticleContent contentWithIds={contentWithIds} />
                    </div>
                </div>
            </div>
            <script type='application/ld+json'>{JSON.stringify(articleSchema)}</script>
        </div>
    )
}
