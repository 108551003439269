import { Route } from 'components/ui'
import { SeoTags } from 'components/seoTags'
import { JournalPage, FRONT_PAGE_KEY } from 'components/journal'
import { SEOManagerApi, SEOManagerImg } from 'utils/actions'

export default function Journal({ page = FRONT_PAGE_KEY, SEOData = null }) {
    return (
        <Route>
            <SeoTags
                title={SEOData?.Title}
                description={SEOData?.Description}
                canonicalPath={SEOData?.Canonical}
                noIndex={SEOData?.Bots}
                mainImg={SEOData?.Image != '' ? `${SEOManagerImg}${SEOData?.Image}` : undefined}
            />
            <JournalPage page={page} />
        </Route>
    )
}

export const getStaticProps = async () => {
    try {
        const responseSEO = await fetch(`${SEOManagerApi}${FRONT_PAGE_KEY}`)
        const SEOData = await responseSEO.json()
        return { props: { SEOData } }
    } catch (error) {
        console.error(error)
        return { props: { SEOData: null } }
    }
}
