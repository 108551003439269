import { useRef } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { useDeferredRendering } from 'hooks'
import { Link, Skeleton } from 'components/ui'
import { useJournalPosts } from './hooks'
import { getJournalPageUrl } from './utils'
import { FRONT_PAGE_KEY, JOURNAL_PAGES } from './constants'
import { ArticlePost } from './articlePost'

import styles from './categorizedPosts.module.scss'

const CATEGORIZED_POSTS_COUNT = 5

const PostsColumn = ({ pageKey = '', title = '' }) => {
    const showVerticalPosts = useMediaPredicate('(max-width: 1199px)')

    const ref = useRef(null)
    const isReady = useDeferredRendering(ref)
    const { data: posts, isLoading: isLoadingPosts } = useJournalPosts(title, 0, CATEGORIZED_POSTS_COUNT, '', isReady)
    const isLoading = !isReady || isLoadingPosts

    const hasPosts = !!posts?.length

    return (
        <div ref={ref} className={styles.column}>
            {Boolean(isLoading || posts?.length) && (
                <Link className={styles.title} href={getJournalPageUrl(pageKey)} label={title} blank={false}>
                    {title}
                </Link>
            )}
            <div className={styles.posts}>
                {isLoading
                    ? Array.from({ length: CATEGORIZED_POSTS_COUNT }, (_, index) => index).map(idx => (
                          <div key={`${pageKey}-post-${idx}`} className={styles.skeleton}>
                              <Skeleton width={'100%'} height={'100%'} />
                          </div>
                      ))
                    : hasPosts && (
                          <>
                              {posts?.map((post, idx) => (
                                  <ArticlePost
                                      key={`${pageKey}-post-${post?.slug}-${idx}`}
                                      post={post?.attributes}
                                      category={title}
                                      isDynamic={showVerticalPosts}
                                  />
                              ))}
                              <Link className={styles.viewMore} href={getJournalPageUrl(pageKey)} blank={false}>
                                  <span>View more</span>
                                  <span className={classNames(`icon icon-arrow-right`, styles.icon)} />
                              </Link>
                          </>
                      )}
            </div>
        </div>
    )
}

export const CategorizedPosts = () => {
    return (
        <div className={styles.categorizedPosts}>
            {JOURNAL_PAGES.filter(p => p.key !== FRONT_PAGE_KEY).map(p => (
                <PostsColumn key={p.key} pageKey={p.key} title={p.name} />
            ))}
        </div>
    )
}
